<template>
  <ul class="menu-nav">
    <!-- begin:: Aside -->
    <KTBrand v-if="isTraineePage"></KTBrand>

    <router-link
      v-if="isTraineePage"
      custom
      :to="`${HomePath}`"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item ml-6"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a href="/" class="menu-link" @click="navigate">
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <li
      aria-haspopup="true"
      data-menu-toggle="click"
      class="menu-item"
      v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    ></li>
    <li
      aria-haspopup="true"
      data-menu-toggle="click"
      class="menu-item menu-item-submenu menu-item-open-dropdown d-none"
      v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Trainee UI </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav megamenu" style="max-width: 1000px">
          <ul class="menu-content">
            <li class="menu-item">
              <h3 class="menu-heading menu-toggle">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> WIP </span>
                <i class="menu-arrow"></i>
              </h3>
              <ul class="menu-inner">
                <router-link
                  to="/trainee/dashboard"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text"> Dashboard </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/trainee/profile"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text"> Profile </span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  to="/trainee/course"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot"><span></span></i>
                      <span class="menu-text"> Course </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import KTBrand from "@/view/layout/brand/Brand.vue";
import UserService from "@/core/services/user.service";
export default {
  name: "KTMenu",
  components: { KTBrand },
  computed: {
    HomePath() {
      let systemType = UserService.getUserSystemType();
      let systemTypePath;
      if (systemType === 1) {
        systemTypePath = "/trainee/";
      } else {
        systemTypePath = "/";
      }
      return systemTypePath;
    },
    isTraineePage() {
      if (this.$route.path.indexOf("/trainee/") !== -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>

<template>
  <div class="topbar-item">
    <div
      class="
        btn btn-icon
        w-auto
        btn-clean
        d-flex
        align-items-center
        btn-lg
        px-2
      "
      id="kt_quick_user_toggle"
    >
      <span
        class="
          text-muted
          font-weight-bold font-size-base
          d-none d-md-inline
          mr-1
        "
      >
        Hi,
      </span>
      <span
        class="
          text-dark-50
          font-weight-bolder font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        <template v-if="userFullname">{{ userFullname }}</template>
        <template v-else>{{ username }}</template>
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="currentUserPersonalInfo.photo" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ username.charAt(0).toUpperCase() }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="
          offcanvas-header
          d-flex
          align-items-center
          justify-content-between
          pb-5
        "
      >
        <h3 class="font-weight-bold m-0">
          User Profile
          <!--          <small class="text-muted font-size-sm ml-2">12 messages</small>-->
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <span class="symbol-label font-size-h1 font-weight-bold">
              {{ username.charAt(0).toUpperCase() }}
            </span>
            <!--            <img-->
            <!--              class="symbol-label"-->
            <!--              :src="currentUserPersonalInfo.photo"-->
            <!--              alt=""-->
            <!--            />-->
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <router-link
              @click.native="closeOffcanvas"
              :to="`/${SystemType}user-profile`"
              class="
                font-weight-bold font-size-h5
                text-dark-75 text-hover-primary
              "
            >
              {{ username.toUpperCase() }}
            </router-link>
            <!--            <div class="text-muted mt-1">Application Developer</div>-->
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <!--                  <span class="navi-icon mr-1">-->
                  <!--                    <span class="svg-icon svg-icon-lg svg-icon-primary">-->
                  <!--                      &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
                  <!--                      <inline-svg-->
                  <!--                        src="media/svg/icons/Communication/Mail-notification.svg"-->
                  <!--                      />-->
                  <!--                      &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
                  <!--                    </span>-->
                  <!--                  </span>-->
                  <span class="navi-text text-muted text-hover-primary">
                    {{ email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <router-link
            :to="`/${SystemType}user-profile`"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/General/Notification2.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">My Profile</div>
                <div class="text-muted">Account settings and more</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <!--          <router-link-->
          <!--            to="/builder"-->
          <!--            @click.native="closeOffcanvas"-->
          <!--            href="#"-->
          <!--            class="navi-item"-->
          <!--          >-->
          <!--            <div class="navi-link">-->
          <!--              <div class="symbol symbol-40 bg-light mr-3">-->
          <!--                <div class="symbol-label">-->
          <!--                  <span class="svg-icon svg-icon-md svg-icon-warning">-->
          <!--                    &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
          <!--                    <inline-svg src="media/svg/icons/Shopping/Chart-bar1.svg" />-->
          <!--                    &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
          <!--                  </span>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="navi-text">-->
          <!--                <div class="font-weight-bold">My Messages</div>-->
          <!--                <div class="text-muted">Inbox and tasks</div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </router-link>-->
          <!--end:Item-->
          <!--begin::Item-->
          <!--          <router-link-->
          <!--            to="/builder"-->
          <!--            @click.native="closeOffcanvas"-->
          <!--            href="#"-->
          <!--            class="navi-item"-->
          <!--          >-->
          <!--            <div class="navi-link">-->
          <!--              <div class="symbol symbol-40 bg-light mr-3">-->
          <!--                <div class="symbol-label">-->
          <!--                  <span class="svg-icon svg-icon-md svg-icon-danger">-->
          <!--                    &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
          <!--                    <inline-svg src="media/svg/icons/Files/Selected-file.svg" />-->
          <!--                    &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
          <!--                  </span>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="navi-text">-->
          <!--                <div class="font-weight-bold">My Activities</div>-->
          <!--                <div class="text-muted">Logs and notifications</div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </router-link>-->
          <!--end:Item-->
          <!--begin::Item-->
          <!--          <router-link-->
          <!--            to="/builder"-->
          <!--            @click.native="closeOffcanvas"-->
          <!--            href="#"-->
          <!--            class="navi-item"-->
          <!--          >-->
          <!--            <div class="navi-link">-->
          <!--              <div class="symbol symbol-40 bg-light mr-3">-->
          <!--                <div class="symbol-label">-->
          <!--                  <span class="svg-icon svg-icon-md svg-icon-primary">-->
          <!--                    &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
          <!--                    <inline-svg-->
          <!--                      src="media/svg/icons/Communication/Mail-opened.svg"-->
          <!--                    />-->
          <!--                    &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
          <!--                  </span>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="navi-text">-->
          <!--                <div class="font-weight-bold">My Tasks</div>-->
          <!--                <div class="text-muted">latest tasks and projects</div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </router-link>-->
          <!--end:Item-->
        </div>
        <!--end::Nav-->
        <div class="separator separator-dashed my-7"></div>
        <!--begin::Notifications-->
        <!--        <div>-->
        <!--          &lt;!&ndash;begin:Heading&ndash;&gt;-->
        <!--          <h5 class="mb-5">Recent Notifications</h5>-->
        <!--          &lt;!&ndash;end:Heading&ndash;&gt;-->
        <!--          <template v-for="(item, i) in list">-->
        <!--            &lt;!&ndash;begin::Item &ndash;&gt;-->
        <!--            <div-->
        <!--              class="d-flex align-items-center rounded p-5 gutter-b"-->
        <!--              v-bind:class="`bg-light-${item.type}`"-->
        <!--              v-bind:key="i"-->
        <!--            >-->
        <!--              <span-->
        <!--                class="svg-icon mr-5"-->
        <!--                v-bind:class="`svg-icon-${item.type}`"-->
        <!--              >-->
        <!--                <span class="svg-icon svg-icon-lg">-->
        <!--                  &lt;!&ndash;begin::Svg Icon&ndash;&gt;-->
        <!--                  <inline-svg :src="item.svg" />-->
        <!--                  &lt;!&ndash;end::Svg Icon&ndash;&gt;-->
        <!--                </span>-->
        <!--              </span>-->
        <!--              <div class="d-flex flex-column flex-grow-1 mr-2">-->
        <!--                <a-->
        <!--                  href="#"-->
        <!--                  class="-->
        <!--                    font-weight-normal-->
        <!--                    text-dark-75 text-hover-primary-->
        <!--                    font-size-lg-->
        <!--                    mb-1-->
        <!--                  "-->
        <!--                >-->
        <!--                  {{ item.title }}-->
        <!--                </a>-->
        <!--                <span class="text-muted font-size-sm">-->
        <!--                  {{ item.desc }}-->
        <!--                </span>-->
        <!--              </div>-->
        <!--              <span-->
        <!--                class="font-weight-bolder py-1 font-size-lg"-->
        <!--                v-bind:class="`text-${item.type}`"-->
        <!--              >-->
        <!--                {{ item.alt }}-->
        <!--              </span>-->
        <!--            </div>-->
        <!--            &lt;!&ndash;end::Item &ndash;&gt;-->
        <!--          </template>-->
        <!--        </div>-->
        <!--end::Notifications-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/modules/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import UserService from "@/core/services/user.service";
import SystemHelper from "@/core/services/systemhelper.service";

export default {
  name: "KTQuickUser",
  data() {
    return {
      list: [
        {
          title: "Another purpose persuade",
          desc: "Due in 2 Days",
          alt: "+28%",
          svg: "media/svg/icons/Home/Library.svg",
          type: "warning",
        },
        {
          title: "Would be to people",
          desc: "Due in 2 Days",
          alt: "+50%",
          svg: "media/svg/icons/Communication/Write.svg",
          type: "success",
        },
        {
          title: "Purpose would be to persuade",
          desc: "Due in 2 Days",
          alt: "-27%",
          svg: "media/svg/icons/Communication/Group-chat.svg",
          type: "danger",
        },
        {
          title: "The best product",
          desc: "Due in 2 Days",
          alt: "+8%",
          svg: "media/svg/icons/General/Attachment2.svg",
          type: "info",
        },
      ],
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      SystemHelper.confirmationDialogHandler(
        { title: "Confirmation", html: "Are you sure you want to logout?" },
        { action: LOGOUT },
        null,
        null
      );
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    SystemType() {
      let systemType = UserService.getUserSystemType();
      let systemTypePath;
      if (systemType === 4) {
        systemTypePath = "SA/";
      } else if (systemType === 3) {
        systemTypePath = "GA/";
      } else if (systemType === 2) {
        systemTypePath = "CA/";
      } else if (systemType === 1) {
        systemTypePath = "trainee/";
      }
      return systemTypePath;
    },
    username() {
      let userData = JSON.parse(UserService.getUserData());
      return userData.username;
    },
    userFullname() {
      let userData = JSON.parse(UserService.getUserData());
      return userData.fullname;
    },
    email() {
      let userData = JSON.parse(UserService.getUserData());
      return userData.email;
    },

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    },
  },
};
</script>
